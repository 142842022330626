import {CountryCode} from 'types/CountryCode'
import {Language} from 'types/Language'

///////////////////////////////////////////////////////////////////////////////

export const CHF_COUNTRIES = [
  CountryCode.CH, // Switzerland
  CountryCode.LI, // Liechtenstein
]

export const GBP_COUNTRIES = [
  CountryCode.GB, // United Kingdom
  CountryCode.GS, // South Georgia and the South Sandwich Islands
]

export const USD_COUNTRIES = [
  CountryCode.AS, // American Samoa
  CountryCode.VG, // British Virgin Islands
  CountryCode.VI, // U.S. Virgin Islands
  CountryCode.EC, // Ecuador
  CountryCode.SV, // El Salvador
  CountryCode.GU, // Guam
  CountryCode.TL, // Timor-Leste
  CountryCode.MH, // Marshall Islands
  CountryCode.FM, // Federated States of Micronesia
  CountryCode.PW, // Palau
  CountryCode.MP, // Northern Mariana Islands
  CountryCode.PR, // Puerto Rico
  CountryCode.TC, // Turks and Caicos Islands
  CountryCode.US, // United States
  CountryCode.IO, // British Indian Ocean Territory
  CountryCode.BQ, // Bonaire, Sint Eustatius and Saba (former Caribbean Netherlands)
  CountryCode.UM, // United States Minor Outlying Islands
]

export const ALL_LANGUAGES = [
  Language.DE,
  Language.FR,
  Language.IT,
  Language.EN,
]
