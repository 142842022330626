export const PATHS = {
  INDEX: '/',

  SOLUTIONS: '/solutions',
  SOLUTIONS_OVERVIEW: '/solutions/overview',

  SOLUTION_NEW: '/solutions/new',
  SOLUTION_DETAILS: '/solutions/:uuid',
  SOLUTION_EDIT: '/solutions/:uuid/edit',
  SOLUTION_ORDER: '/solutions/:uuid/order',

  TRANSACTIONS: '/transactions',
  TRANSACTION_DETAILS: '/transactions/:transactionUuid',

  SUBSCRIPTIONS: '/subscriptions',
  SUBSCRIPTION_DETAILS: '/subscriptions/:subscriptionUuid',

  PAYOUTS: '/payouts',
  PAYOUT_DETAILS: '/payouts/:payoutUuid',

  SETTINGS: '/settings',
  SETTINGS_OTHER: '/settings/*',

  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_PROFILE_USER: '/settings/profile/user',
  SETTINGS_PROFILE_SECURITY: '/settings/profile/security',
  SETTINGS_PROFILE_APPEARANCE: '/settings/profile/appearance',
  SETTINGS_PROFILE_OTHER: '/settings/profile/*',

  SETTINGS_ORGANISATION: '/settings/organisation',
  SETTINGS_ORGANISATION_INFO: '/settings/organisation/info',
  SETTINGS_ORGANISATION_TAX_RECEIPT: '/settings/organisation/tax-receipt',
  SETTINGS_ORGANISATION_TAX_RECEIPT_CUSTOM_TEMPLATE:
    '/settings/organisation/tax-receipt/custom-template',
  SETTINGS_ORGANISATION_USERS: '/settings/organisation/users',
  SETTINGS_ORGANISATION_OTHER: '/settings/organisation/*',

  SETTINGS_COMMUNICATION: '/settings/communication',
  SETTINGS_COMMUNICATION_EMAIL: '/settings/communication/email',
  SETTINGS_COMMUNICATION_OTHER: '/settings/communication/*',

  SETTINGS_PAYMENT_PROVIDERS: '/settings/payment-providers',
  SETTINGS_PAYMENT_PROVIDERS_DETAILS:
    '/settings/payment-providers/:paymentProvider',

  SETTINGS_INTEGRATIONS: '/settings/integrations',
  SETTINGS_INTEGRATIONS_DETAILS: '/settings/integrations/:integrationUuid',

  //TODO: uncomment when webhooks section is finished
  // SETTINGS_WEBHOOKS: '/settings/webhooks',
  // SETTINGS_WEBHOOKS_WEBHOOKS: '/settings/webhooks/webhooks',
  // SETTINGS_WEBHOOKS_EVENTS: '/settings/webhooks/events',
  // SETTINGS_WEBHOOKS_OTHER: '/settings/webhooks/*',

  SIGN_UP: '/sign-up',
} as const

export type Path = (typeof PATHS)[keyof typeof PATHS]

///////////////////////////////////////////////////////////////////////////////

export const getSolutionDetailsUrl = (uuid: string) => {
  return PATHS.SOLUTION_DETAILS.replace(':uuid', uuid)
}

export const getSolutionEditUrl = (uuid: string) => {
  return PATHS.SOLUTION_EDIT.replace(':uuid', uuid)
}

export const getSolutionOrderUrl = (uuid: string) => {
  return PATHS.SOLUTION_ORDER.replace(':uuid', uuid)
}

export const getTransactionDetailsUrl = (transactionUuid: string) => {
  return PATHS.TRANSACTION_DETAILS.replace(':transactionUuid', transactionUuid)
}

export const getSubscriptionDetailsUrl = (subscriptionUuid: string) => {
  return PATHS.SUBSCRIPTION_DETAILS.replace(
    ':subscriptionUuid',
    subscriptionUuid
  )
}

export const getPayoutDetailsUrl = (payoutUuid: string) => {
  return PATHS.PAYOUT_DETAILS.replace(':payoutUuid', payoutUuid)
}
