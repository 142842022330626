import {
  createHashRouter,
  createRoutesFromElements,
  redirect,
  Route,
} from 'react-router-dom'
import {GenericError} from 'components/GenericError'
import {PATHS} from './paths'

///////////////////////////////////////////////////////////////////////////////

/* eslint-disable mobx/missing-observer */

/**
 * Wrappers
 */
const RouterWrapper = () => import('wrappers/RouterWrapper')
const ProvidersWrapper = () => import('wrappers/ProvidersWrapper')
const AuthWrapper = () => import('wrappers/AuthWrapper')
const AppWrapper = () => import('wrappers/AppWrapper')
const ProtectedWrapper = () => import('wrappers/ProtectedWrapper')
const PublicWrapper = () => import('wrappers/PublicWrapper')
const NotFoundWrapper = () => import('wrappers/NotFoundWrapper')

/**
 * Layouts
 */
const MainLayout = () => import('layouts/MainLayout')
const AuthLayout = () => import('layouts/AuthLayout')

/**
 * Pages
 */
const Dashboard = () => import('pages/dashboard')

const Solutions = () => import('pages/solutions')
const SolutionNew = () => import('pages/solutions.new')
const SolutionDetails = () => import('pages/solutions.$uuid')
const SolutionEdit = () => import('pages/solutions.$uuid.edit')
const SolutionOrder = () => import('pages/solutions.$uuid.order')

const TransactionsPage = () => import('pages/transactions')
const SubscriptionsPage = () => import('pages/subscriptions')
const PayoutsPage = () => import('pages/payouts')

const SettingsProfile = () => import('pages/settings/profile')
const SettingsProfileUser = () => import('pages/settings/profile/user')
const SettingsProfileSecurity = () => import('pages/settings/profile/security')
const SettingsProfileAppearance = () =>
  import('pages/settings/profile/appearance')

const SettingsOrganisation = () => import('pages/settings/organisation')
const SettingsOrganisationInfo = () =>
  import('pages/settings/organisation/info')
const SettingsOrganisationTaxReceipt = () =>
  import('pages/settings/organisation/tax-receipt')
const SettingsOrganisationTaxReceiptOverview = () =>
  import('pages/settings/organisation/tax-receipt/overview')
const SettingsOrganisationCustomTaxReceiptTemplatePage = () =>
  import('pages/settings/organisation/tax-receipt/custom-tax-receipt-template')
const SettingsOrganisationUsersPage = () =>
  import('pages/settings/organisation/users')

const SettingsCommunication = () => import('pages/settings/communication')
const SettingsCommunicationEmail = () =>
  import('pages/settings/communication/email')

const SettingsPaymentProviders = () =>
  import('pages/settings/payment-providers')

const SettingsIntegrations = () => import('pages/settings/integrations')

//TODO: uncomment when webhooks section is finished
// const SettingsWebhooks = () => import('pages/settings/webhooks')
// const SettingsWebhooksWebhooks = () =>
//   import('pages/settings/webhooks/webhooks')
// const SettingsWebhooksEvents = () => import('pages/settings/webhooks/events')

const SignUp = () => import('pages/sign-up')

const NotFound = () => import('pages/not-found')

/**
 * `RouterWrapper` provides the `RouterStore` to the components tree.
 * It listens to location changes and updates the store.
 * It also renders the `NavigationProgress` component.
 *
 * `ProvidersWrapper` provides all the most important stores
 * to the components tree.
 *
 * `AuthWrapper` triggers the user fetching and shows a spinner
 * while the user is being fetched.
 * It also triggers the fetching of the other most important data.
 *
 * `AppWrapper` instantiates some stores (like tracking services).
 * Shows a CookieConsent component.
 *
 * `ProtectedWrapper` checks if the user is authenticated.
 * It prevents the user from accessing the routes down the tree
 * if user is not authenticated.
 *
 * `PublicWrapper` checks if the user is not authenticated.
 * It prevents the user from accessing the routes down the tree
 * if user is authenticated.
 *
 * `NotFoundWrapper` handles some special redirection cases for both
 * authenticated and unauthenticated users.
 */
// prettier-ignore
export const ROUTER = createHashRouter(
  createRoutesFromElements(
    <Route lazy={RouterWrapper} errorElement={<div>Error</div>}>
      <Route lazy={ProvidersWrapper}>
        <Route lazy={AuthWrapper}>
          <Route lazy={AppWrapper} errorElement={<GenericError />}>
            <Route lazy={ProtectedWrapper}>
              <Route lazy={MainLayout}>
                <Route path={PATHS.INDEX} lazy={Dashboard} />

                <Route
                  path={PATHS.SOLUTIONS}
                  loader={() => redirect(PATHS.SOLUTIONS_OVERVIEW)}
                />
                <Route path={PATHS.SOLUTIONS_OVERVIEW} lazy={Solutions} />
                <Route path={PATHS.SOLUTION_NEW} lazy={SolutionNew} />
                <Route path={PATHS.SOLUTION_DETAILS} lazy={SolutionDetails} />
                <Route path={PATHS.SOLUTION_EDIT} lazy={SolutionEdit} />
                <Route path={PATHS.SOLUTION_ORDER} lazy={SolutionOrder} />

                <Route path={PATHS.TRANSACTIONS} lazy={TransactionsPage} />
                <Route
                  path={PATHS.TRANSACTION_DETAILS}
                  lazy={TransactionsPage}
                />

                <Route path={PATHS.SUBSCRIPTIONS} lazy={SubscriptionsPage} />
                <Route
                  path={PATHS.SUBSCRIPTION_DETAILS}
                  lazy={SubscriptionsPage}
                />

                <Route path={PATHS.PAYOUTS} lazy={PayoutsPage} />
                <Route path={PATHS.PAYOUT_DETAILS} lazy={PayoutsPage} />

                <Route
                  path={PATHS.SETTINGS}
                  loader={() => redirect(PATHS.SETTINGS_ORGANISATION)}
                />
                <Route
                  path={PATHS.SETTINGS_OTHER}
                  loader={() => redirect(PATHS.SETTINGS_ORGANISATION)}
                />

                <Route path={PATHS.SETTINGS_PROFILE} lazy={SettingsProfile}>
                  <Route
                    index
                    loader={() => redirect(PATHS.SETTINGS_PROFILE_USER)}
                  />
                  <Route
                    path={PATHS.SETTINGS_PROFILE_USER}
                    lazy={SettingsProfileUser}
                  />
                  <Route
                    path={PATHS.SETTINGS_PROFILE_SECURITY}
                    lazy={SettingsProfileSecurity}
                  />
                  <Route
                    path={PATHS.SETTINGS_PROFILE_APPEARANCE}
                    lazy={SettingsProfileAppearance}
                  />
                  <Route
                    path={PATHS.SETTINGS_PROFILE_OTHER}
                    loader={() => redirect(PATHS.SETTINGS_PROFILE_USER)}
                  />
                </Route>

                <Route
                  path={PATHS.SETTINGS_ORGANISATION}
                  lazy={SettingsOrganisation}
                >
                  <Route
                    index
                    loader={() => redirect(PATHS.SETTINGS_ORGANISATION_INFO)}
                  />
                  <Route
                    path={PATHS.SETTINGS_ORGANISATION_OTHER}
                    loader={() => redirect(PATHS.SETTINGS_ORGANISATION_INFO)}
                  />
                  <Route
                    path={PATHS.SETTINGS_ORGANISATION_INFO}
                    lazy={SettingsOrganisationInfo}
                  />

                  <Route
                    path={PATHS.SETTINGS_ORGANISATION_TAX_RECEIPT}
                    lazy={SettingsOrganisationTaxReceipt}
                  >
                    <Route
                      index
                      lazy={SettingsOrganisationTaxReceiptOverview}
                    />
                    <Route
                      path={
                        PATHS.SETTINGS_ORGANISATION_TAX_RECEIPT_CUSTOM_TEMPLATE
                      }
                      lazy={SettingsOrganisationCustomTaxReceiptTemplatePage}
                    />
                  </Route>
                  <Route
                    path={PATHS.SETTINGS_ORGANISATION_USERS}
                    lazy={SettingsOrganisationUsersPage}
                  />
                </Route>

                <Route
                  path={PATHS.SETTINGS_COMMUNICATION}
                  lazy={SettingsCommunication}
                >
                  <Route
                    index
                    loader={() => redirect(PATHS.SETTINGS_COMMUNICATION_EMAIL)}
                  />
                  <Route
                    path={PATHS.SETTINGS_COMMUNICATION_EMAIL}
                    lazy={SettingsCommunicationEmail}
                  />
                  <Route
                    path={PATHS.SETTINGS_COMMUNICATION_OTHER}
                    loader={() => redirect(PATHS.SETTINGS_COMMUNICATION_EMAIL)}
                  />
                </Route>

                <Route
                  path={PATHS.SETTINGS_PAYMENT_PROVIDERS}
                  lazy={SettingsPaymentProviders}
                />
                <Route
                  path={PATHS.SETTINGS_PAYMENT_PROVIDERS_DETAILS}
                  lazy={SettingsPaymentProviders}
                />

                <Route
                  path={PATHS.SETTINGS_INTEGRATIONS}
                  lazy={SettingsIntegrations}
                />
                <Route
                  path={PATHS.SETTINGS_INTEGRATIONS_DETAILS}
                  lazy={SettingsIntegrations}
                />

                {/*TODO: uncomment when webhooks section is finished
                <Route path={PATHS.SETTINGS_WEBHOOKS} lazy={SettingsWebhooks}>
                  <Route
                    index
                    loader={() => redirect(PATHS.SETTINGS_WEBHOOKS_WEBHOOKS)}
                  />
                  <Route
                    path={PATHS.SETTINGS_WEBHOOKS_WEBHOOKS}
                    lazy={SettingsWebhooksWebhooks}
                  />
                  <Route
                    path={PATHS.SETTINGS_WEBHOOKS_EVENTS}
                    lazy={SettingsWebhooksEvents}
                  />
                  <Route
                    path={PATHS.SETTINGS_WEBHOOKS_OTHER}
                    loader={() => redirect(PATHS.SETTINGS_WEBHOOKS_WEBHOOKS)}
                  />
                </Route> */}
              </Route>
            </Route>

            <Route lazy={PublicWrapper}>
              <Route lazy={AuthLayout}>
                <Route path={PATHS.SIGN_UP} lazy={SignUp} />
              </Route>
            </Route>

            <Route lazy={NotFoundWrapper}>
              <Route lazy={MainLayout}>
                <Route path="*" lazy={NotFound} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
)

////////////////////////////////////////////////////////////////////////////////
