import {inject} from 'react-ioc'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag, {type NotifiableError} from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {ReactRouterRoutingProvider} from '@bugsnag/react-router-performance'
import {isAxiosError} from 'axios'
import {SessionStore} from 'new/stores/SessionStore'
import {AppMode} from 'types/AppMode'

////////////////////////////////////////////////////////////////////////////////

export class BugsnagService {
  #sessionStore = inject(this, SessionStore)

  #bugsnagApiKey =
    import.meta.env.MODE === AppMode.PROD
      ? import.meta.env.VITE_BUGSNAG_API_KEY
      : (window.BUGSNAG_API_KEY ?? import.meta.env.VITE_BUGSNAG_API_KEY)

  #bugsnagEnabled =
    window.BUGSNAG_ENABLED ?? import.meta.env.VITE_BUGSNAG_ENABLED

  constructor() {
    void this.#initBugsnag()
  }

  async #initBugsnag() {
    if (this.#bugsnagApiKey && this.#bugsnagEnabled) {
      Bugsnag.start({
        apiKey: this.#bugsnagApiKey,
        plugins: [new BugsnagPluginReact()],
        releaseStage: import.meta.env.MODE,
        autoTrackSessions: false,
        enabledErrorTypes: {
          unhandledRejections: false,
        },
      })

      const routes = 'config/routes'
      /**
       * Workaround for Vite's HMR issue: https://raisenow.atlassian.net/browse/WEB-2493
       *
       * Vite's HMR reloads files when their dependencies change. Since `BugsnagService` imports `routes`,
       * and `routes` is used in many places, this will eventually lead to a circular import.
       *
       * Fixing the circular import without affecting code quality is tricky.
       *
       * This "hack" to force Vite to ignore the import is the simplest solution.
       * This should not be cause major issues, since Bugsnag is only used in production.
       */
      const {ROUTER} = await import(
        /* @vite-ignore */
        routes
      )

      BugsnagPerformance.start({
        apiKey: this.#bugsnagApiKey,
        routingProvider: new ReactRouterRoutingProvider(ROUTER.routes),
      })
    }
  }

  /**
   * @see {NotifiableError} in `@bugsnag/js`
   */
  #isNotifiableError(error: unknown): error is NotifiableError {
    if (typeof error === 'object' && error !== null) {
      if ('errorClass' in error && 'errorMessage' in error) {
        return true
      }

      if ('name' in error && 'message' in error) {
        return true
      }
    }

    if (typeof error === 'string') {
      return true
    }

    return false
  }

  get enabled() {
    return this.#bugsnagApiKey && this.#bugsnagEnabled
  }

  notifyError(error: unknown, payload?: any) {
    const {user_uuid} = this.#sessionStore.getTokenInfo()

    // skip 401 errors
    if (isAxiosError(error) && error.response?.status === 401) {
      return
    }

    // skip non-notifiable errors
    if (!this.#isNotifiableError(error)) {
      Bugsnag.notify('Fatal Error: Unknown error structure', event => {
        event.setUser(user_uuid)
        event.addMetadata('payload', {
          error,
          payload,
        })
      })
      return
    }

    Bugsnag.notify(error, event => {
      let err = error

      if (isAxiosError(error)) {
        event.request.url = error.request.responseURL
        err = error.response?.data
      }

      event.setUser(user_uuid)
      event.addMetadata('payload', {
        error: err,
        payload,
      })
    })

    // eslint-disable-next-line no-console
    console.log(error)
  }
}
