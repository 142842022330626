import {inject} from 'react-ioc'
import {comparer, makeAutoObservable, reaction} from 'mobx'
import {posthog} from 'posthog-js'
import {SignupUrlParameters} from 'components/SignUpForm/SignupUrlParameters'
import {PATHS} from 'config/paths'
import {UI_LANGUAGE} from 'constants/cookie_entry_names'
import {TOKEN} from 'constants/local_storage_entry_names'
import {decrypt} from 'lib/helpers/cryptAES'
import {
  getParamsFromSearch,
  getSearchFromParams,
} from 'lib/helpers/searchParams'
import {ctx} from 'new/ctx'
import {LocalStorageService} from 'new/services/LocalStorageService'
import {AppConfigStore} from './AppConfigStore'
import {RouterStore} from './RouterStore'

///////////////////////////////////////////////////////////////////////////////

type TokenInfo = Partial<{
  token: string
  type: string
  created_at: number
  expires_in: number
  user_uuid: string
  organisation_uuid: string
}>

///////////////////////////////////////////////////////////////////////////////

export class SessionStore {
  #localStorageService = inject<LocalStorageService>(this, LocalStorageService)
  #routerStore = inject<RouterStore>(this, RouterStore)
  #appConfigStore = inject<AppConfigStore>(this, AppConfigStore)
  #disposers: (() => void)[] = []

  constructor() {
    makeAutoObservable(this)

    ctx.sessionStore = this

    this.#initEffects()

    this.createSession()
  }

  createSession() {
    const tokenInfo = this.getTokenInfoFromUrl()

    if (tokenInfo.token) {
      this.setTokenInfo({...tokenInfo, created_at: Date.now()})

      // Remove token info from "hash" part of URL
      setTimeout(() => {
        this.#routerStore.navigate(PATHS.INDEX, {replace: true})
      })
    }
  }

  removeSession() {
    // FIXME: Move to PosthogService and let the service
    // react whenever the user has logged out so that
    // SessionStore does not need a dependency to PosthogService
    posthog.reset()

    this.removeTokenInfo()
    window.location.href = this.signOutUrl
  }

  restoreLocation(userUuid: string) {
    const authParams = new URLSearchParams(window.location.search)
    const lastUserId = authParams.get('last_user')
    const lastPath = authParams.get('last_path')

    /**
     * Restore `last_path` if possible.
     */
    if (
      lastPath &&
      lastPath !== PATHS.SIGN_UP &&
      (lastUserId === userUuid || !lastUserId) &&
      /**
       * Makes sure to only allow paths from the same origin
       * This is here to prevent XSS attacks to e.g. redirect to a phishing site
       * or execute JavaScript via e.g. the URL
       * http://localhost:8080/?last_path=javascript:alert(1)
       */
      new URL(document.baseURI).origin ===
        new URL(lastPath, document.baseURI).origin
    ) {
      this.#routerStore.navigate(lastPath, {replace: true})
    }

    /**
     * Remove `last_path` and `last_user` URL parameters
     */
    authParams.delete('last_path')
    authParams.delete('last_user')
    const currentLocation = new URL(window.location.href)
    currentLocation.search = authParams.toString()
    window.history.replaceState({}, document.title, currentLocation.href)
  }

  get signInUrl() {
    const {auth_base_url, oauth_client_id, self_base_url} =
      this.#appConfigStore.data
    const {pathname, search} = this.#routerStore.location
    const {user_uuid} = this.getTokenInfo()

    const params: Record<string, string> = {
      client_id: oauth_client_id,
      redirect_uri: self_base_url,
      response_type: 'token',
    }

    params.last_path = pathname + search

    if (user_uuid) {
      params.last_user = user_uuid
    }

    return `${auth_base_url}/oauth2/authorize${getSearchFromParams(params)}`
  }

  get signOutUrl() {
    const {auth_base_url} = this.#appConfigStore.data
    const logoutUrl = new URL(`${auth_base_url}/logout`)
    const orgDataParam = this.getOrganisationDataParam()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const orgDataParamFormatted = orgDataParam?.replace(/\s/g, '+')
    const {self_base_url} = this.#appConfigStore.data
    const pathName = window.location.pathname
    let targetUrl = this.signInUrl

    if (orgDataParam) {
      posthog.reset()
      this.removeTokenInfo()
      targetUrl = pathName
        ? `${self_base_url}${pathName}#${PATHS.SIGN_UP}?${SignupUrlParameters.ORGANISATION_DATA}=${orgDataParamFormatted}`
        : `${self_base_url}/#${PATHS.SIGN_UP}?${SignupUrlParameters.ORGANISATION_DATA}=${orgDataParamFormatted}`
    }

    logoutUrl.searchParams.set('target-url', targetUrl)

    return logoutUrl.href
  }

  getTokenInfoFromUrl(): TokenInfo {
    const {access_token, token_type, expires_in} = getParamsFromSearch(
      window.location.hash.substring(1)
    )

    return {
      token: access_token as string,
      type: token_type as string,
      expires_in: expires_in ? parseInt(expires_in as string) : undefined,
    }
  }

  getTokenInfo() {
    return this.#localStorageService.get<TokenInfo>(TOKEN) ?? {}
  }

  setTokenInfo(data: TokenInfo) {
    this.#localStorageService.set(TOKEN, data)
  }

  updateTokenInfo(data: TokenInfo) {
    const tokenInfo = this.getTokenInfo()
    this.setTokenInfo({...tokenInfo, ...data})
  }

  removeTokenInfo() {
    this.#localStorageService.remove(TOKEN)
  }

  checkIsTokenExpired() {
    const {created_at, expires_in} = this.getTokenInfo()

    if (created_at && expires_in) {
      const now = Date.now()

      return now > created_at + expires_in * 1000
    }

    return false
  }

  clearLocalStorage() {
    this.#localStorageService.clear()
  }

  clearCookies() {
    const cookiesToKeep = [UI_LANGUAGE]
    const domain = window.location.hostname.match(/(\.\w+){2}$/)?.[0]

    for (const cookie of document.cookie.split(';')) {
      const [name] = cookie.split('=')
      if (name && !cookiesToKeep.includes(name)) {
        document.cookie = `${name}=;max-age=0;path=/;domain=${domain}`
      }
    }
  }

  getOrganisationDataParam() {
    return this.#routerStore.searchParams.organisation_data
  }

  getDecryptedOrganisationData() {
    const {sign_up_link_private_key} = this.#appConfigStore.data
    const orgDataEncrypted = this.getOrganisationDataParam()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const orgDataFormatted = orgDataEncrypted?.replace(/\s/g, '+')

    if (orgDataFormatted) {
      return decrypt(orgDataFormatted, sign_up_link_private_key)
    }
  }

  #initEffects() {
    this.#disposers.push(
      reaction(
        () => this.#routerStore.location,
        () => {
          if (this.checkIsTokenExpired()) {
            this.removeSession()
          }
        },
        {
          fireImmediately: false,
          equals: comparer.structural,
        }
      )
    ),
      this.#disposers.push(
        reaction(
          () => this.#routerStore.location,
          () => {
            if (this.getOrganisationDataParam()) {
              if (this.getTokenInfo().token) {
                this.removeSession()
              }
            }
          },
          {
            fireImmediately: true,
          }
        )
      )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }
}
