import {type FC} from 'react'
import {useInstance} from 'react-ioc'
import {CheckOutlined, GlobalOutlined} from '@ant-design/icons'
import {Dropdown} from 'antd'
import {type MenuItemType} from 'antd/es/menu/interface'
import cn from 'classnames'
import {observer} from 'mobx-react-lite'
import {getLocale} from 'lib/helpers/locale'
import {useAppConfig} from 'lib/hooks/useAppConfig'
import {ctx} from 'new/ctx'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {AppConfigStore} from 'new/stores/AppConfigStore'
import {CurrentUserStore} from 'new/stores/CurrentUserStore'
import {ALL_LANGUAGES} from '../../constants/OrganisationMetadata'
import styles from './index.module.scss'

////////////////////////////////////////////////////////////////////////////////

export const LanguageMenu: FC = observer(() => {
  const appConfigStore = useInstance(AppConfigStore)
  const {language} = useAppConfig()
  const {data: user} = useInstance(CurrentUserStore)
  const analytics = useInstance(AnalyticsEventService)

  const changeLanguage = (language: string) => {
    appConfigStore.update({language})

    if (user) {
      void ctx.apiStore!.patch(`/users/${user.uuid}`, {
        ...user,
        locale: getLocale(language),
      })

      analytics.addUserProperties({locale: getLocale(language)})
    }
  }

  const isCurrentLanguage = (lang: string) => lang === language

  const languages = ALL_LANGUAGES.sort((a, b) => a.localeCompare(b))

  const menuItems = languages.map(
    (lng, i): MenuItemType => ({
      key: i,
      onClick: () => changeLanguage(lng),
      className: styles.dropdownItem,
      label: (
        <span data-cy={`languageMenu.item.${lng}`}>
          {isCurrentLanguage(lng) && <CheckOutlined />}

          <span
            className={cn([
              styles.headerMenuItemLanguageName,
              {
                [styles.headerMenuItemLanguageNameCurrent]:
                  isCurrentLanguage(lng),
              },
            ])}
          >
            {lng}
          </span>
        </span>
      ),
    })
  )

  return (
    <Dropdown menu={{items: menuItems}} trigger={['click']}>
      <div data-cy="languageMenu" className={styles.headerMenuItem}>
        <GlobalOutlined />
      </div>
    </Dropdown>
  )
})
