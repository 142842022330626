import {type FC} from 'react'
import {useTranslation} from 'react-i18next'
import {observer} from 'mobx-react-lite'
import {useWebsiteLink, Webpage} from 'lib/hooks/useWebsiteLink'
import styles from './index.module.scss'

///////////////////////////////////////////////////////////////////////////////

const AppFooter: FC = observer(() => {
  const {t} = useTranslation()
  const termsLink = useWebsiteLink(Webpage.TERMS)
  const privacyLink = useWebsiteLink(Webpage.PRIVACY)

  return (
    <div className={styles.cont}>
      <a
        className={styles.footerLink}
        href={termsLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t('common.footer_links.terms_of_service.label')}
      </a>
      <a
        className={styles.footerLink}
        href={privacyLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t('common.footer_links.privacy_policy.label')}
      </a>
      <span>
        {t('common.footer_links.copyright.label', {
          current_year: new Date().getFullYear(),
        })}
      </span>
    </div>
  )
})

export default AppFooter
