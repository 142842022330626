import {type ComponentProps} from 'react'
import {useInstance} from 'react-ioc'
import Icon from '@ant-design/icons'
import {observer} from 'mobx-react-lite'
import AutoWidthIconSvg from 'assets/images/icons/auto-width.svg?react'
import CloseIconSvg from 'assets/images/icons/close-icon.svg?react'
import CustomWidthIconSvg from 'assets/images/icons/custom-width.svg?react'
import DefaultWidthIconSvg from 'assets/images/icons/default-width.svg?react'
import FullscreenDarkIconSvg from 'assets/images/icons/fullscreen-dark.svg?react'
import FullscreenIconSvg from 'assets/images/icons/fullscreen.svg?react'
import MoonIconSvg from 'assets/images/icons/moon.svg?react'
import OpenIconSvg from 'assets/images/icons/open-icon.svg?react'
import SelectGiftIconDarkSvg from 'assets/images/icons/select-icn-gift-dark.svg?react'
import SelectGiftIconSvg from 'assets/images/icons/select-icn-gift.svg?react'
import SelectHeartIconDarkSvg from 'assets/images/icons/select-icn-heart-dark.svg?react'
import SelectHeartIconSvg from 'assets/images/icons/select-icn-heart.svg?react'
import SelectSecureIconDarkSvg from 'assets/images/icons/select-icn-secure-dark.svg?react'
import SelectSecureIconSvg from 'assets/images/icons/select-icn-secure.svg?react'
import StyleCardDarkIconSvg from 'assets/images/icons/style-card-dark.svg?react'
import StyleCardIconSvg from 'assets/images/icons/style-card.svg?react'
import StyleFrameDarkIconSvg from 'assets/images/icons/style-frame-dark.svg?react'
import StyleFrameIconSvg from 'assets/images/icons/style-frame.svg?react'
import StyleNoneIconSvg from 'assets/images/icons/style-none.svg?react'
import SunIconSvg from 'assets/images/icons/sun.svg?react'
import {ThemeStore} from 'new/stores/ThemeStore'

///////////////////////////////////////////////////////////////////////////////

type IconComponentProps = ComponentProps<typeof Icon>

///////////////////////////////////////////////////////////////////////////////

export const CloseIcon = observer((props: IconComponentProps) => (
  <Icon component={CloseIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const OpenIcon = observer((props: IconComponentProps) => (
  <Icon component={OpenIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const DefaultWidthIcon = observer((props: IconComponentProps) => (
  <Icon component={DefaultWidthIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const AutoWidthIcon = observer((props: IconComponentProps) => (
  <Icon component={AutoWidthIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const CustomWidthIcon = observer((props: IconComponentProps) => (
  <Icon component={CustomWidthIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const StyleNoneIcon = observer((props: IconComponentProps) => (
  <Icon component={StyleNoneIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const StyleFrameIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? StyleFrameDarkIconSvg : StyleFrameIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const StyleCardIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? StyleCardDarkIconSvg : StyleCardIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const FullscreenIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? FullscreenDarkIconSvg : FullscreenIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const SelectHeartIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SelectHeartIconDarkSvg : SelectHeartIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const SelectSecureIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SelectSecureIconDarkSvg : SelectSecureIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const SelectGiftIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SelectGiftIconDarkSvg : SelectGiftIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const ToggleThemeIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SunIconSvg : MoonIconSvg
  return <Icon component={icon} {...props} />
})
